import domReady from '@wordpress/dom-ready';
import { CountUp } from 'countup.js';

domReady(() => {
  const numbers = document.querySelectorAll('.statistic-item-block__number');

  if (0 < numbers.length) {
    numbers.forEach((number) => {
      const numberValue = number.textContent;
      const animOptions = {
        enableScrollSpy: true,
      };
      const animation = new CountUp(number, numberValue, animOptions);

      if (!animation.error) {
        animation.start();
      }
    });
  }
});
